<template>
  <div class="detail">
    <!-- 预警情况 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.planId" placeholder="请选择测评计划" size="small" clearable>
        <el-option v-for="item in PlanList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-select v-model="gaugeId" placeholder="请选择量表" size="small" clearable>
        <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="searchInfo.dimensionId" placeholder="请选择维度" size="small" @focus="getDimensionsList" clearable>
        <el-option v-for="item in DimensionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-select v-model="searchInfo.level" placeholder="请选择预警等级" size="small" clearable>
        <el-option label="一级" :value="1"></el-option>
        <el-option label="二级" :value="2"></el-option>
        <el-option label="三级" :value="3"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" @focus="getOrganizationsList" size="small"
        clearable placeholder="请选择组织机构" :show-all-levels="false"
        :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>

      <el-select v-model="searchInfo.roleId" placeholder="请选择角色" size="small" @focus="getRolesList" clearable>
        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      
      <div class="bottom">

        <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
        <el-option label="全部" :value="4"></el-option>
        <el-option label="男" :value="1"></el-option>
        <el-option label="女" :value="2"></el-option>
        <el-option label="未填写" :value="3"></el-option>

      </el-select>
        <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px"></el-input>
        <span>至</span>
        <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
        <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          style="margin-right:10px" format="yyyy-MM-dd" value-format="yyyy-MM-dd 00:00:00">
        </el-date-picker>
        <span>至</span>
        <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
        <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" size="small" clearable>
        </el-input>
        <el-button type="primary" size="small" @click="search">查询</el-button>
      </div>

    </div>
    <div class="tableContent">
      <div class="top"><span>预警测评记录</span>
        <el-dropdown split-button type="primary" @command="handleCommand" size="small">
          导出数据
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">维度得分</el-dropdown-item>
            <el-dropdown-item command="2">答题数据</el-dropdown-item>
            <el-dropdown-item command="3">答题记录</el-dropdown-item>
            <el-dropdown-item command="4">人员列表</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown split-button type="primary" size="small" @command="handleCommandReport">
          导出报告
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">测评报告</el-dropdown-item>
            <el-dropdown-item command="2">预警报告</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button type="primary" size="small">导出数据</el-button> -->
        <!-- <el-button type="primary" size="small">导出报告</el-button> -->
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="userId" label="编号" width="80" fixed>
          </el-table-column>
          <el-table-column prop="username" label="登录名" width="120" show-overflow-tooltip fixed>
          </el-table-column>
          <el-table-column prop="nickname" label="姓名" width="120" fixed>
          </el-table-column >
          <el-table-column prop="sex" label="性别" width="80" fixed>
            <template slot-scope="scope">
              <span v-if="scope.row.sex === 1">男</span>
              <span v-else-if="scope.row.sex === 2">女 </span>
              <span v-else-if="scope.row.sex === 3">未填写</span>
            </template>
          </el-table-column>
          <el-table-column prop="deptName" label="组织机构" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="roleName" label="角色" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="planName" label="测评计划名称" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="gaugeName" label="量表名称" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="dimensionName" label="维度名称" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header" >
              <span>预警级别</span>
              <el-tooltip class="item" effect="dark" content="如果触发多个预警级别，只显示最高的预警级别。" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span v-if="scope.row.level === 1">一级</span>
              <span v-else-if="scope.row.level === 2">二级</span>
              <span v-else-if="scope.row.level === 3">三级</span>
            </template>
          </el-table-column>
          <el-table-column prop="submitTime" label="提交时间" width="160">
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.submitTime">{{scope.row.submitTime.slice(0,10)}}</span>
            </template> -->
          </el-table-column>
          <el-table-column prop="time" label="答题时长" width="100">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="lookWarning(scope.row.userAnswerId)">预警报告</el-button>
              <el-button type="text" size="small" @click="lookView(scope.row.userAnswerId)">测评报告</el-button>
              <el-button type="text" size="small" @click="lookAnswer(scope.row.userAnswerId)">答题记录</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
            layout="total, prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="numTotal">
      <span>基本数据统计</span>
      <div class="table">
        <el-table :data="numData" style="width: 100%" border>
          <el-table-column label="量表名称">
            <template slot-scope="scope">
              <span>xxx量表名称</span>
            </template>
          </el-table-column>
          <el-table-column label="预警样本数">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="总样本数">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="预警比例">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="icon" id="icon">

      </div>
    </div>
    <div class="dimensionTotal">
      <span>维度数据统计</span>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column label="维度名称">
            <template slot-scope="scope">
              <span>xxx量表名称</span>
            </template>
          </el-table-column>
          <el-table-column label="预警名称">
            <template slot-scope="scope">
              <span>xxx量表名称</span>
            </template>
          </el-table-column>
          <el-table-column label="预警级别">
            <template slot-scope="scope">
              <span>一级</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="预警范围">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="预警样本数">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="占总预警比例">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="占总样本比例">
            <template slot-scope="scope">
              <span>1200</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class=""></div>
    </div> -->
  </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import { getDimensionList, getProjectList } from '@/api/gauge/dimension'
import { getPlanList } from '@/api/evaluationManagement/plan'
import { getEvaluationWarningList, exportWarning } from '@/api/dangerWarning/warning'
export default {
  data() {
    return {
      loading: false,
      // 顶部搜索的内容
      searchInfo: {
        deptId: null, // 组织机构id
        dimensionId: null, // 维度id
        roleId: null, // 角色id
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        nickname: null, // 姓名
        startAge: null, // 开始年龄
        sex: null, // 性别
        planId: null, // 计划id
        level: null, // 预警等级
        endAge: null // 结束年龄
      },
      // 当前选择的量表id
      gaugeId: null,
      // 当前量表的名称
      gaugeName: '',
      // 角色数组
      RolesList: null,
      // 组织机构数组
      OrganizationsList: null,
      // 预警记录数据
      tableData: [],
      // 基础数据数组
      numData: [{
        id: 1
      }],
      // 预警记录分页数据
      paging: {
        p: 1,
        ps: 16,
        total: 0,
      },
      // 量表数组
      GaugeList: [],
      // 维度数组
      DimensionList: [],
      // 预警列表
      WarningList: [],
      PlanList: [],
      selectList: [] // 用户选择的数组

    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        // this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
      }

    },
    // 获取角色列表
    async getRolesList() {
      const res = await getRoleList();
      console.log(res);
      if (res.code === 200) {
        this.RolesList = res.data;
        // this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
      }

    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getList();
    },
    // 获取量表列表
    async getGaugesList() {
      const res = await getGaugeList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.GaugeList = res.data.records;
      }
    },
    // // 获取计划数组
    // async getPlanList() {
    //   const res = await getPlanList({ id: this.gaugeId });
    //   console.log(res);
    //   if (res.code === 200) {
    //     this.PlanList = res.data;
    //   }


    // },
    //  根据量表id获取维度
    async getDimensionsList() {
      if (this.gaugeId !== null) {
        this.getProjects();
        const res = await getDimensionList({ id: this.gaugeId });
        console.log(res)
        if (res.code === 200) {
          this.DimensionList = [...this.DimensionList,...res.data]
        }
      } else {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
    // 获取项目数列表
    async getProjects() {
      const res = await getProjectList(this.gaugeId);
      console.log(res);
      if(res.code === 200) {
        this.DimensionList = [...this.DimensionList,...res.data]
      }

    },
    // // 根据量表id获取预警列表
    // async getWarningsList() {
    //   if (this.gaugeId !== null) {
    //     const res = await getWarningList({ gaugeId: this.gaugeId })
    //     console.log(res)
    //     if (res.code === 200) {
    //       this.WarningList = res.data
    //     }
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: '请先选择量表！'
    //     });
    //   }
    // },
    // 判断是否选择了量表
    judgeGauge() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
    // 点击搜索
    search() {
      // console.log(Number(new Date(this.searchInfo.startTime)));
      // console.log(Number(new Date(this.searchInfo.endTime)));
      if (Number(new Date(this.searchInfo.startTime)) > Number(new Date(this.searchInfo.endTime)) && this.searchInfo.startTime !== null && this.searchInfo.endTime !== null) {
        this.$message({
          showClose: true,
          message: '开始时间不能大于结束时间！'
        });
      } else if (Number(this.searchInfo.startAge) > Number(this.searchInfo.endAge) && this.searchInfo.startTime !== null && this.searchInfo.endTime !== null) {
        this.$message({
          showClose: true,
          message: '开始年龄不能大于结束年龄！'
        });
      } else {
        this.getList();
      }

    },
    // 点击查看预警报告
    lookWarning(val) {
      this.$router.push({
        path: '/home/warningReport',
        query: {
          id: val,
          gaugeId: this.gaugeId
        }
      })
    },
    // 点击查看测评报告
    lookView(val) {
      this.$router.push({
        path: '/home/evaluationReport',
        query: {
          id: val,
          gaugeId: this.gaugeId
        }
      })
    },
    // 点击查看答题记录
    lookAnswer(val) {
      this.$router.push({
        path: '/home/answerReport',
        query: {
          id: val,
          gaugeId: this.gaugeId
        }
      })
    },
    // 获取预警记录列表
    async getList() {
      this.loading = true;
      let info = { ...this.searchInfo, gaugeId: this.gaugeId, p: this.paging.p, ps: this.paging.ps };
      if (info.sex === 4) {
        info.sex = null;
      }
      // console.log(info)
      const res = await getEvaluationWarningList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.loading = false;
        this.tableData = res.data.warningListVOList;
        this.paging.total = res.data.count;
        // this.getDimensionsList();
        // if (res.data.warningListVOList.length > 0) {
        //   this.gaugeName = res.data.warningListVOList[0].gaugeName
        // }
      }
    },
    // 获取测评计划列表
    async getPlansList() {
      const res = await getPlanList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.PlanList = res.data.records;
        // this.tableData = res.data.records;
        // this.paging.total = res.data.total;
      }
    },
    // 用户点击导出
    async handleCommand(val) {
      if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.userAnswerId);
        });
        
        console.log( 'type',val)
        let list = arr.join(',');
        console.log('ids', list)
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/warning/export?ids=${list}&type=${val}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择测评记录！'
        });
      }

    },
     // 用户点击导出报告
     async handleCommandReport(val) {
      // 1.预警报告 2. 测评报告
      if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.userAnswerId);
        });
        
        console.log( 'type',val)
        let list = arr.join(',');
        console.log('ids', list)
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/warning/export/report?ids=${list}&type=${val}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择测评记录！'
        });
      }

    },
    // 用户点击选择
    handleSelectionChange(val) {
      this.selectList = val;
    }
  },
  created() {

    if (this.$route.query.id) {
      this.gaugeId = Number(this.$route.query.id);
      // this.getList();
    }
    this.getList();
    this.getPlansList();
    this.getGaugesList();
    // this.getOrganizationsList();
    // this.getRolesList();
  }
}
</script>

<style lang="scss" scoped>
.detail {
  padding: 20px;

  // width: 100%;
  // overflow-x: hidden;
  .searchBtn {
    .bottom {
      margin-top: 20px;

      ::v-deep.el-date-editor {
        width: 150px !important;
      }

      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  .tableContent {
    .top {

      span,
      .el-button {
        display: inline-block;
        margin-right: 10px;
        margin-left: 0px;
      }

      .el-dropdown {
        margin-right: 10px;
      }
    }

    .block {
      margin-top: 20px;
    }
  }

  .numTotal {
    .icon {
      height: 200px;
      width: 200px;
    }

  }

  .tableContent,
  .numTotal,
  .dimensionTotal {
    margin-top: 40px;

    .table {
      margin-top: 10px;
    }
  }
}
</style>